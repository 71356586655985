import * as React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Menu from "../components/menu";
import "../../src/css/journal/style.css"

const LayoutJournal = ({ location, title, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const siteTitle = data.site.siteMetadata?.title || `Title`

  return (
    <div className="tl-wrapper">
      <Menu right noOverlay/>
      
      <Helmet>
        <html className="journal"  lang="en" />
      </Helmet>

      <header className="my-5 pt-5 pb-3 text-center">
        <AniLink className="text-decoration-none" fade to="/journal">
          <h1 className="font-title">journal</h1>
        </AniLink>
      </header>
      
      <main className="row m-5">
        {children}
      </main>

      <footer className="text-center pt-5 font-title">
        © {new Date().getFullYear()} {siteTitle}
      </footer>
    </div>
  )
}

export default LayoutJournal
