import * as React from "react"
import { graphql } from "gatsby"

import Layout from "./layout2022"
import Seo from "../../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const JournalIndex = ({ data, location }) => {
  return (
    <Layout location={location}>
      <Seo title="221106 - Things I bought in this autumns" />
      <h2 className="font-title-sub text-center">Things I bought in this autumns</h2>
      <p>The colors of leaves changing on the street. It's been a while since the last post on journal.</p>
      <p>After the summer's gone (I was dying for the heat), it's a very nice season to start new things. For me this autumns, I'm trying to do something I've not done before or to make a little difference in usual days. Today I'm gonna write about a bit with new things I bought recently.</p>
      <h3 className="font-title-sub text-center">1. Training wear from adidas</h3>
      <p>Finally I started going to a gym! I didn't have any physical hobbies, so I decided to have something new. For my motivation, I got all wears in adidas.</p>
      <p>Still a half month since started, but physical exercise keeps me feel positive, and of course works good for my health. I feel very refreshing after training is finished.</p>
      <h3 className="font-title-sub text-center">2. Backpack from Muji</h3>
      <p>A few months ago, one day I went hiking while travelling, and was a great experience, so I want to go again. I was looking for a new backpack that I can use for hiking, in addition, in daily use like for going to the library with bringing books and my laptop.</p>
      <p>With broad choices, I ended up buying in Muji, just costed about 4000 yen. This backpack is so light with useful pockets, and big enough, even I can go 1-2 days trip with it. Can't wait for going out!</p>
      <h3 className="font-title-sub text-center">3. Flavor tea from Muji</h3>
      <p>I'm a coffee person, but these days I'm into tea too. The selection of tea in Muji is a little adventure! I bought two packs: apple rooibos tea and grapefruit green tea for iced.</p>
      <p>For apple rooibos, I felt little apple scent and almost just rooibos tea, but grapefruit green tea, it's my favorite. I put water and a tea pack in a bottle and put into the fridge in a whole night. Basically a green tea, but also you can smell the fresh grapefruit in it. I think it's good for summer too. I'll keep updating if I find new one!</p>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
