import * as React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../../../components/layoutJournal"
import Seo from "../../../components/seo"

const JournalIndex = ({ data, location, children }) => {
  return (
    <Layout location={location}>
      <Seo title="journal" />
      <div className="col-sm-10 font-text">
        <div className="post-content mx-5">{children}</div>
      </div>
      <div className="col-sm-2">
        <ul className="font-title">
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022">2022</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1229">- 1229</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1228">- 1228</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1120">- 1120</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1118">- 1118</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1113">- 1113</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/1106">- 1106</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0816">- 0816</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0812">- 0812</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0726">- 0726</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0719">- 0719</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0716">- 0716</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0714">- 0714</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0712">- 0712</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0709">- 0709</AniLink></li>
          <li className="list-unstyled my-1"><AniLink className="text-decoration-none link-dark" fade to="/journal/2022/0706">- 0706</AniLink></li>
        </ul>
      </div>
    </Layout>
  )
}

export default JournalIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
